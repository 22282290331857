import { render, staticRenderFns } from "./sasscolor.vue?vue&type=template&id=5edb865a&scoped=true&lang=pug"
import script from "./sasscolor.vue?vue&type=script&lang=js"
export * from "./sasscolor.vue?vue&type=script&lang=js"
import style0 from "./sasscolor.vue?vue&type=style&index=0&id=5edb865a&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5edb865a",
  null
  
)

export default component.exports