<template lang='pug'>

-vb = "0 0 500 500"
-p0 = "M170.5,250.5v-102"
-p1 = "M12.5,147.5c0,7,0,14,0,21.1c0,30,1.4,58.7,24.5,80.8c14.7,14,34.4,20.1,54.5,20.1 c28.8,0,55.9-15.9,69.7-41.2c13.4-24.6,9.3-53.8,9.3-80.8"
-p2 = "M170.5,147.5c0,7,0,14,0,21.1c0,16.7-1.4,32.9,4.3,48.9c4.3,11.9,11,23.1,20.2,31.9 c14.7,14,34.4,20.1,54.5,20.1c65.5,0,79-61,79-72c0-5.5,0-50,0-50"
-p3 = "M328.5,147.5c0,0,0,47,0,50c0,12.5,13.5,72,79,72c32.9,0,60.4-18,73-48.4 c9.4-22.7,6-49.5,6-73.6"

-j1 = "M43.4,2.4C43.4,1,42.3,0,40.9,0c-1.3,0-2.4,0.9-2.4,2.3l0.1,26.2h0c0,12-8,18.6-18.1,18.7 c-6.8,0-12.6-3.3-15.7-8.9c-0.6-1.1-2.2-1.5-3.4-0.7C0,38.3-0.4,39.8,0.4,41c4,7,11.4,11.5,20,11.5c12.7,0,23-9,23.2-23h0L43.4,2.4z"

-e1 = "M53.6,26.7C53.6,12.1,65,0.3,79.2,0.3c13.5,0,24.3,10.7,25.4,24.5v0.1v0.3c0,1.4-1.2,2.4-2.6,2.4H58.7 c0.5,11.5,9.6,20.2,20.4,20.2c7,0,13.4-3.7,17-9.6c0.8-1.3,2.4-1.5,3.4-0.9c1.2,0.9,1.6,2.4,0.9,3.7C95.9,48.2,88.1,53,79.2,53 C65,53,53.6,41.1,53.6,26.7z M59.1,22.5h39.9C97.2,12.7,88.8,5.4,79.2,5.4C69.3,5.4,61,12.5,59.1,22.5z"
-s1 = "M112.8,42c0.9-1.1,2.5-1.3,3.7-0.3c3.7,2.7,9.5,6.1,16.1,6.1c4.1,0,7.6-1.3,10-3.2c2.6-1.7,4-4.1,4-6.4 c-0.1-2.5-1.1-4.2-3.8-6.4c-2.3-1.7-6-3-11.2-3.9l-0.2-0.1c-4.7-0.9-8.9-1.9-11.9-4.1c-3.3-1.9-5.7-5.4-5.7-9.5 c0-4.2,2.4-7.8,5.7-10.3c3.1-2.2,7.5-3.5,12.9-3.5c6.8,0,11.6,3.1,15.7,5.7c1.2,0.9,1.5,2.5,0.8,3.7c-0.8,1.2-2.4,1.5-3.5,0.8 c-3.9-2.6-7.7-5.3-12.9-5.3c-4.3,0-7.7,1.2-10,3c-2.2,1.7-3.3,3.9-3.3,6c0,2.1,1,3.7,3,5.3c2.4,1.6,5.9,2.9,10.7,3.7h0.2 c5.1,0.8,9.1,2.3,12.8,4.3c3.4,2.3,5.9,6.3,5.9,10.7s-2.5,8-6,10.6c-3.7,2.7-8,4.2-13.1,4.2c-8.5-0.1-15.2-4.1-19.5-7.5 C112,44.5,111.9,43.1,112.8,42z"
-s2 = "M159.2,42c0.9-1.1,2.5-1.3,3.7-0.3c3.7,2.7,9.5,6.1,16.1,6.1c4.1,0,7.6-1.3,10-3.2c2.6-1.7,4-4.1,4-6.4 c-0.1-2.5-1.1-4.2-3.8-6.4c-2.3-1.7-6-3-11.2-3.9l-0.2-0.1c-4.7-0.9-8.9-1.9-11.9-4.1c-3.3-1.9-5.7-5.4-5.7-9.5 c0-4.2,2.4-7.8,5.7-10.3c3.1-2.2,7.5-3.5,12.9-3.5c6.8,0,11.6,3.1,15.7,5.7c1.2,0.9,1.5,2.5,0.8,3.7c-0.8,1.2-2.4,1.5-3.5,0.8 c-3.9-2.6-7.7-5.3-12.9-5.3c-4.3,0-7.7,1.2-10,3c-2.2,1.7-3.3,3.9-3.3,6c0,2.1,1,3.7,3,5.3c2.4,1.6,5.9,2.9,10.7,3.7h0.2 c5.1,0.8,9.1,2.3,12.8,4.3c3.4,2.3,5.9,6.3,5.9,10.7s-2.5,8-6,10.6c-3.7,2.7-8,4.2-13.1,4.2c-8.5-0.1-15.2-4.1-19.5-7.5 C158.4,44.5,158.3,43.1,159.2,42z"
-e2 = "M206.6,26.7c0-14.6,11.4-26.4,25.6-26.4c13.5,0,24.3,10.7,25.4,24.5v0.1v0.3c0,1.4-1.2,2.4-2.6,2.4h-43.2 c0.5,11.5,9.6,20.2,20.4,20.2c7,0,13.4-3.7,17-9.6c0.8-1.3,2.4-1.5,3.4-0.9c1.2,0.9,1.6,2.4,0.9,3.7c-4.5,7.2-12.4,11.9-21.3,11.9 C218,53,206.6,41.1,206.6,26.7z M212.1,22.5H252c-1.7-9.9-10.1-17.1-19.8-17.1C222.3,5.4,214,12.5,212.1,22.5z"
-w1 = "M340.8,0.2c-1.4,0-2.5,1.1-2.5,2.6v28.7c0,8.6-3,15.6-12,16.3v-0.1c0,0.1-0.5,0.1-1,0.1 c-8.8-0.3-12-7.4-12-16.3v-7.7c0-1.5-1.3-2.5-2.5-2.6c-0.1,0-0.2,0-0.3,0c-0.1,0,0,0,0,0c-1.3,0-2.2,1.1-2.2,2.6v7.7 c0,9-4.1,16.2-13.1,16.3c-8.9-0.1-12.9-7.3-12.9-16.3V2.8c0-1.5-1.1-2.6-2.5-2.6s-2.5,1.1-2.5,2.6v28.7c0,11.8,5.7,21.5,17.5,21.5 c0.5,0,1.5,0,1.5-0.1v0c7-0.3,11.3-3.6,14-8.7c2.8,5.3,7.8,8.7,14.9,8.7c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0 c11.8,0,17.5-9.7,17.5-21.5V2.8C343.3,1.3,342.2,0.2,340.8,0.2z"
-e3 = "M353.5,26.7c0-14.6,11.4-26.4,25.6-26.4c13.5,0,24.3,10.7,25.4,24.5v0.1v0.3c0,1.4-1.2,2.4-2.6,2.4h-43.2 c0.5,11.5,9.6,20.2,20.4,20.2c7,0,13.4-3.7,17-9.6c0.8-1.3,2.4-1.5,3.4-0.9c1.2,0.9,1.6,2.4,0.9,3.7C395.9,48.2,388,53,379.1,53 C364.9,53,353.5,41.1,353.5,26.7z M359,22.5h39.9c-1.7-9.9-10.1-17.1-19.8-17.1C369.2,5.4,360.9,12.5,359,22.5z"
-l1 = "M442,2.4c0-1.4,1.5-2.4,2.9-2.4c1.5,0,2.8,1.2,2.8,2.6v49.2c0,1.4-1.3,2.6-2.8,2.6c-1.4,0-2.9-1.4-2.9-2.7 V2.4z"
-l2 = "M415.8,2.4c0-1.4,1.5-2.4,2.9-2.4c1.5,0,2.8,1.2,2.8,2.6v49.2c0,1.4-1.3,2.6-2.8,2.6c-1.4,0-2.9-1.4-2.9-2.7 V2.4z"
-s3 = "M461.1,42c0.9-1.1,2.5-1.3,3.7-0.3c3.7,2.7,9.5,6.1,16.1,6.1c4.1,0,7.6-1.3,10-3.2c2.6-1.7,4-4.1,4-6.4 c-0.1-2.5-1.1-4.2-3.8-6.4c-2.3-1.7-6-3-11.2-3.9l-0.2-0.1c-4.7-0.9-8.9-1.9-11.9-4.1c-3.3-1.9-5.7-5.4-5.7-9.5 c0-4.2,2.4-7.8,5.7-10.3c3.1-2.2,7.5-3.5,12.9-3.5c6.8,0,11.6,3.1,15.7,5.7c1.2,0.9,1.5,2.5,0.8,3.7c-0.8,1.2-2.4,1.5-3.5,0.8 c-3.9-2.6-7.7-5.3-12.9-5.3c-4.3,0-7.7,1.2-10,3c-2.2,1.7-3.3,3.9-3.3,6c0,2.1,1,3.7,3,5.3c2.4,1.6,5.9,2.9,10.7,3.7h0.2 c5.1,0.8,9.1,2.3,12.8,4.3c3.4,2.3,5.9,6.3,5.9,10.7s-2.5,8-6,10.6c-3.7,2.7-8,4.2-13.1,4.2c-8.5-0.1-15.2-4.1-19.5-7.5 C460.3,44.5,460.2,43.1,461.1,42z"

-dot = "M125,12.5c0,6.9-5.6,12.5-12.4,12.5c0,0-30,0-30.1,0c0,0,30,0,30,0c-6.9,0-12.5-5.6-12.5-12.5S105.6,0,112.5,0 c0,0-30,0-30,0c0,0,30,0,30.1,0C119.4,0,125,5.6,125,12.5z"
#wrap
  router-link.home(to='/')
  #crop
    #name
      svg(viewBox='0 0 500 100')
        g.name1
          path.j1(d=j1)
          path.e1(d=e1)
          path.s1(d=s1)
          path.s2(d=s2)  
          path.e2(d=e2)  
          path.w1(d=w1)
          path.e3(d=e3)
          path.l1(d=l1)
          path.l2(d=l2)  
          path.s3(d=s3)
        g.name2
          path.j1(d=j1)
          path.e1(d=e1)
          path.s1(d=s1)
          path.s2(d=s2)  
          path.e2(d=e2)  
          path.w1(d=w1)
          path.e3(d=e3)
          path.l1(d=l1)
          path.l2(d=l2)  
          path.s3(d=s3)
      span.subhead designer + developer
  #logo
    svg(viewBox='0 0 500 500')
      g.logo0
        line.ln1(x1='91' y1='298.5' x2='91' y2='-100')
        line.ln2(x1='251' y1='202.5' x2='251' y2='-100')
        line.ln3(x1='411' y1='202.5' x2='411' y2='-100')
      g.logo1
        path.p0(d=p0)
        path.p1(d=p1)
        path.p2(d=p2)
        path.p3(d=p3)
      g.logo2
        path.p0(d=p0)
        path.p1(d=p1)
        path.p2(d=p2)
        path.p3(d=p3)

</template>

<script>
import {TimelineMax} from 'gsap/TweenMax'
import DrawSvg from '../assets/drawSvg.js'
export default {
  mounted () {
    var p0 = $('.p0')
    var p1 = $('.p1')
    var p2 = $('.p2')
    var p3 = $('.p3')
    var pp = $('.p1,.p2,.p3')
    var ppp = $('.logo2 .p1,.logo2 .p2,.logo2 .p3')
    var logo = $('#logo')
    var logo2 = $('.logo1 path')
    var jw = $('#name')
    var ee = $('.e1,.e2,.e3')
    var e1 = $('.e1')
    var e2 = $('.e2')
    var e3 = $('.e3')
    var ll = $('.j1,.e1,.s1,.s2,.e2,.w1,.e3,.l1,.l2,.s3,.subhead')
    var lnln = $('.ln1,.ln2,.ln3')
    var page = $('content')
    var head = $('header')
    var git = $('#badge')
    var tl = new TimelineMax()
    var tl2 = new TimelineMax()

    tl.timeScale(1.3)

    if(this.$route.path==='/') {
      
      TweenMax.delayedCall(1, myFunction)
      TweenMax.set(page, {scale: 0.98,opacity:0})

      function myFunction() {
        tl.to(logo2, 0, {stroke:'#dce4e5', strokeWidth:'38px'})
        tl.to(logo, 0, {opacity:1})
        tl.from(ppp,3, {stroke:'#ced9dd'})
        tl.from(p1, 0.5, {ease: Power2.easeInOut,drawSVG:'0% 0%'},0.5)
        tl.from(p2, 0.5, {ease: Power2.easeInOut,drawSVG:'0% 0%'}, 1)
        tl.from(p3, 0.5, {ease: Power2.easeInOut,drawSVG:'0% 0%'}, 1.5)
        tl.to(p1, 1, {ease: Back.easeIn.config(2), y:120},2.95)
        tl.to(p0, 1, {ease: Power3.easeOut, y:10}, '-=0.7')
        tl.to(p0, 0, {opacity:1}, '-=0.4')
        tl.staggerFrom(lnln, 0.5, {opacity:0,drawSVG:'0% 0%'},0.15)

        tl2.to(logo, 1.5, {y:50},0.35)
        tl2.to(logo, 0.5, {ease: Power1.easeInOut, top:'-100px',left:'-25px',marginLeft:'0',marginTop:'0',scale:0.5,rotation:22},2)
        tl2.to(logo2, 0.5, {stroke:'#fff', strokeWidth:'34px'},'-=0.5')
        tl2.staggerFrom(ll, 0.55, {opacity:0},0.06)
        tl2.to(jw, 0.65, {ease: Power3.easeOut, x:'-110%'},2.75)
        tl2.to(ee, 0.25, {ease: Back.easeInOut.config(3),rotation:-12,x:-5,y:6},4.25)
        tl2.to(page, 0.25, {opacity:1},'-=0.75')
        tl2.to(page, 0.75, {zIndex:1,scale: 1},'-=0.75')
        tl2.to(head, 0.5, {opacity:1})
        tl2.to(git, 0.5, {ease: Power1.easeIn,right:'-90px',opacity:1},'-=1')
      }
    }
    else {
      tl.set(logo, {opacity:1})
      tl.set(p1, {y:120})
      tl.set(p0, {y:10})
      tl.set(p0, {opacity:1})
      tl.set(logo, {y:50})
      tl.set(logo, {top:'-100px',left:'-25px',marginLeft:'0',marginTop:'0',scale:0.5,rotation:22})
      tl.set(logo2, {stroke:'#fff', strokeWidth:'34px'})
      tl.set(ll, {opacity:1})
      tl.set(jw, {x:'-110%'})
      tl.set(ee, {rotation:-12,x:-5,y:6})
      tl.set(head, {opacity:1})
      tl.set(git, {right:'-90px',opacity:1})
    }
  }
}
</script>

<style lang='stylus'>
bg0 = #f7f7f7
Lclr = #eceff1
Lclr1 = #eceff1
Lclr2 = #f4f6f7
Lclr3 = #fafafb
Lclr4 = #1f2b30

#logo
  position absolute
  opacity 0
  width 350px
  height 200px
  left 50%
  top 50%
  margin-left -(@width / 2)
  margin-top -(@height / 2)
  pointer-events none
  svg
    overflow visible
    height 350px

#name
  position absolute
  padding-left 25px
  width 267px
  height 100px
  left 100%
  svg
    overflow visible
    height 54px
  .subhead
    font-family 'Quicksand', sans-serif
    font-size 22px
    font-weight 400
    display block
    position relative
    top -22px
    right 2px
    text-align right
a.home
  position absolute
  display block
  width 200px
  height 125px
  top 0
  left 40px
  z-index 11

#crop
  position absolute
  width 295px
  height 100px
  left 235px
  top 80px
  overflow hidden
  padding 5px 0 0 5px
  z-index 10
  pointer-events none

.logo1 path
  fill none
  stroke Lclr
  stroke-width 34
  stroke-linecap round
  stroke-linejoin round
  stroke-miterlimit 10

.logo2 path
  fill none
  stroke Lclr4
  stroke-width 20
  stroke-linecap round
  stroke-linejoin round
  stroke-miterlimit 10

.logo0 line
  fill none
  stroke-width 160
  stroke-linecap round
  stroke-linejoin round
  stroke-miterlimit 10
  &.ln1
    stroke Lclr1
  &.ln2
    stroke Lclr2
  &.ln3
    stroke Lclr3

.name1 path
  fill #f3f3f3
  stroke #f3f3f3
  stroke-width 12

.name2 path
  fill darken(Lclr4,10%)

.p0
  opacity 0

.s3
  transform translateY(2px)
</style>