<template lang='pug'>
footer
        nav
            a(href='/resume') resume
</template>

<script>
export default {

}
</script>

<style lang='stylus'>
footer
    position fixed
    bottom 0
    width 100%
    text-align right
    padding-right 3em
    font-size 1.5em
    color #f7f7f7
    a
        color #f7f7f7
        text-decoration none
        display block
        height 20px
        cursor default
</style>